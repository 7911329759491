import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextSamotvorbaWebCCh = ({ colors = {} }) => {
    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;
    const myAnimationOptionsCCh = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">

            <h3 id="web-stranka-od-nas" className="toc toc0"><span>Webstránka od nás</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="web-stranka-od-nas"
            >
                <div>Ale môžte vytvorenie webstránky nechať na nás</div>
                <div>Tvorba web stránok cez javascript programovanie je trochu zložitejšia oproti Wordpressu.</div>
                <div>Môžte si objednať u nás super rýchle a super flexibilné webstránky vytvorené s Gatsby.js.</div>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextSamotvorbaWebCCh;